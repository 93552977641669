import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { CircularProgressWithLabel } from "@material-ui/core";
import CircularProgressWithLabel from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Task = (props) => {
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState({})
    const [enabled, setEnabled] = React.useState(true)
    const [disabled, setDisabled] = React.useState({})
    const [dialog, setDialog] = React.useState({})
    const [buttonLink, setButtonLink] = React.useState('')
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    async function buttonPressed() {
        setOpen(false);
        if (buttonLink !== '') {
            if (buttonLink.charAt(0) !== '/') {
                window.location.href = buttonLink
            } else {
                const url = props.awsUrl + buttonLink
                const updatetask = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });


                window.location.href = '/'
            }

        } else {
            window.location.href = url
        }


    };
    // const [url, setUrl] = useState('')
    //assume one pareameter is already baked in
    let url = props.button_link + '&uuid=' + props.uuid
    if (props.pass_uuid === false) {
        // brought in for vitall linking - needs to be explicitly false for backwards compatibility
        url = props.button_link
    }
    // url = '#'
    useEffect(() => {
        if (props.dialog) {
            setEnabled(false)
            var checked_state = {}
            var disabled_state = {}
            props.dialog.options.map((x, q) => {

                return (

                    x.dialog_text.map((y, q2) => {
                        let id = q.toString() + '-' + q2.toString()
                        checked_state = { ...checked_state, [id]: false }
                        if (q2 === 0) {
                            disabled_state = { ...disabled_state, [id]: false }
                        } else {
                            disabled_state = { ...disabled_state, [id]: true }
                        }


                        console.log('ID', id)
                    })
                )
            })
            setChecked(checked_state)
            setDisabled(disabled_state)
            setDialog(props.dialog)
        }
    }, [props])

    var header = props.header
    // if (props.current_progress && props.total_actions) {
    //     var progress = (props.current_progress * 100 / props.total_actions).toFixed(0)
    //     var header = header + ' (' + progress + '% complete)'
    // }
    var complete = props.current_progress * 100 / props.total_actions
    if (props.progress !== -1) {

        if (props.status === 'done') {
            complete = 100
        }
    } else {
        complete = -1
    }
    const process_task = (url) => {
        if (props.dialog) {
            setOpen(true);
        }
        else {
            window.location.href = url
        }

    }
    const handleCheckboxChange = (event) => {
        let checked_state = {}
        let disabled_state = {}
        checked_state[event.target.id] = event.target.checked
        let all_ticked = true
        if (event.target.checked) {
            const pri = event.target.id.split('-')[0]
            setButtonLink(dialog.options[pri]['button_link'])
            for (var x in checked) {
                const pri_x = x.split('-')[0]
                if (pri_x !== pri) {
                    checked_state[x] = false
                    disabled_state[x] = true
                } else {
                    if (x != event.target.id && !checked[x]) {

                        all_ticked = false
                    }
                    disabled_state[x] = false
                }
            }
        } else {
            all_ticked = false
            const sec = event.target.id.split('-')[1]
            if (sec === '0') {
                for (var y in checked) {

                    checked_state[y] = false
                    const secy = y.split('-')[1]
                    if (secy !== '0') {
                        disabled_state[y] = true
                    }
                    else {
                        disabled_state[y] = false
                    }


                }
            }

        }
        setEnabled(all_ticked)
        setChecked({ ...checked, ...checked_state })
        setDisabled({ ...disabled, ...disabled_state })
    };
    const title_header = "\u2714 " + header
    var subheader = props.subheader
    if (props.selected_opt !== '' && props.selected_opt >= 0) {
        subheader = subheader + ' ' + props.dialog.options[props.selected_opt].task_update_patient
    }
    return (
        <Card>
            {props.status !== 'done' ? (
                <> {props.status === 'not done' ?
                    <>
                        {(complete >= 0) ? (
                            <CardHeader

                                avatar={
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgressWithLabel variant="determinate" value={complete} />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography variant="caption" component="div" color="text.secondary">
                                                {complete !== 100 ? <> {`${Math.round(complete)}%`}</> :
                                                    <>&#9989;</>}

                                            </Typography>
                                        </Box>
                                    </Box>
                                }


                                titleTypographyProps={{ variant: 'h5' }}
                                title={header}
                                subheaderTypographyProps={{ variant: 'h6' }}
                                subheader={props.subheader}
                            />
                        ) : (
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5' }}
                                title={header}
                                subheaderTypographyProps={{ variant: 'h6' }}
                                subheader={props.subheader}
                            />
                        )}
                    </> : <><CardHeader
                        titleTypographyProps={{ variant: 'h5' }}
                        title={header}
                        subheaderTypographyProps={{ variant: 'h6' }}
                        subheader={subheader}

                    /> </>}
                </>
            ) : (<>
                <CardHeader
                    titleTypographyProps={{ variant: 'h5' }}
                    title={title_header}
                    subheaderTypographyProps={{ variant: 'h6' }}
                    subheader={subheader}

                />

            </>)}


            {(props.status === 'not done') ?
                <>
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">

                            {props.description}<br />
                            {complete !== 101 ?
                                <>
                                    <Button color="primary" variant="contained" onClick={() => { process_task(url) }}>
                                        {props.button}
                                    </Button>
                                </> :
                                <></>

                            }

                        </Typography>
                    </CardContent>
                </> : <></>
            }
            {props.dialog ?

                <>
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            {props.dialog.title}
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                <div dangerouslySetInnerHTML={{ __html: props.dialog.text }} />
                                {/* {} */}
                            </Typography>

                            {props.dialog.options ?
                                <>


                                    {props.dialog.options.map((x, q) => {


                                        return (

                                            x.dialog_text.map((y, q2) => {
                                                let id = q.toString() + '-' + q2.toString()

                                                var text = y
                                                if (text.substring(0, 2) === '__') {
                                                    text = text.substring(2)
                                                    return (
                                                        <FormControlLabel
                                                            label={<Typography style={{ fontWeight: 'bold' }}>{text}</Typography>}
                                                            control={< Checkbox id={id} checked={checked[id]} disabled={disabled[id]} onChange={handleCheckboxChange} />}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <FormControlLabel
                                                            label={text}
                                                            control={< Checkbox id={id} checked={checked[id]} disabled={disabled[id]} onChange={handleCheckboxChange} />}
                                                        />
                                                    )
                                                }

                                            }




                                            )

                                        )


                                    })}
                                </> :
                                <> </>}


                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={buttonPressed} disabled={!enabled}>
                                {props.dialog.submit}
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                </>
                : <></>}

        </Card >

    )

}

export default Task;