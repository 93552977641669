
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "./survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import awsvars from "../aws.json"
import axios from "axios";
Amplify.configure(awsExports);

// Amplify.configure(awsExports);

const PatientDetails = (props) => {
    const [idToken, setIdToken] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [patient, setPatient] = useState({})
    async function fetch() {
        if (props.auth && props.awsUrl) {

            const drchrono = await axios.get(props.awsUrl + '/patient_details', { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });

            setPatient(drchrono.data)
            setLoaded(true)
        }

    }

    useEffect(() => {
        fetch()

    }, [props.auth, props.awsUrl]);

    return (
        <div>
            {loaded ?
                <>
                    {
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                                <h1>Profile Pic</h1>

                            </Grid> */}
                            <Grid item xs={12} sm={12} md={9} lg={9}>


                                First name: {patient.first_name}<br />
                                Last name: {patient.last_name}<br />

                                Date of birth: {patient.date_of_birth}<br />
                                Email: {patient.email}<br />

                                Address: {patient.address}<br />
                                {patient.city} {patient.state} {patient.zip_code}<br />
                            </Grid>
                        </Grid>




                    } </> : <>< CircularProgress /></>
            }




        </div >

    );
}
export default withAuthenticator(PatientDetails)
