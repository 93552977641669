import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import { Button } from '@material-ui/core';

import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
Amplify.configure(awsExports);
const AVRT = (props) => {

    return (
        <div>

            <h1>Welcome to AVRT</h1>
            <div>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in sapien molestie, pellentesque dui ac, consequat mauris. Pellentesque et lacinia ipsum. Praesent purus lorem, ultricies eu pulvinar venenatis, ornare eu purus. Morbi laoreet nulla mauris, at blandit lorem consequat eget. Donec fringilla semper laoreet. In at lorem et sapien tempus pellentesque. Maecenas ac maximus nibh. Donec mollis semper dui, ut iaculis purus rutrum at. Aliquam condimentum viverra auctor. Pellentesque in efficitur mauris, sit amet pulvinar sem.
                </p>

                <p>
                    Vestibulum luctus nibh leo, at mattis massa fermentum at.Suspendisse vitae nulla dictum, tempus ante eget, mattis lacus.Aliquam erat volutpat.Curabitur pulvinar nulla bibendum eleifend scelerisque.Ut ornare orci leo.Pellentesque a erat pharetra, ultricies diam a, tempus dolor.Suspendisse nec vehicula tortor, a dignissim ante.Aenean lacinia massa elit, eget sagittis neque volutpat eget.In lorem ante, rhoncus eu varius a, scelerisque eu nisl.Nulla vestibulum mi id ex luctus sodales.
                </p><p>
                    Aenean eu odio elementum, feugiat sapien non, bibendum lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Aliquam faucibus massa et commodo egestas.Duis at semper arcu.Nunc gravida ultricies tellus, eu bibendum eros eleifend sed.Donec rutrum ante lorem, nec vehicula augue lacinia at.Vestibulum leo tortor, sagittis sed condimentum sed, tempus ac lorem.Ut at justo pulvinar, blandit lectus ut, dignissim nunc.
                </p>
            </div>
            <div>
                <Button color="primary" variant="contained" onClick={() => { window.location.href = "/register" }}>
                    Sign-up to AVRT
                </Button>
                <br /><br />
                <Button color="primary" variant="contained" onClick={() => { window.location.href = "/patient" }}>
                    Sign in to Patient Portal
                </Button>
            </div>

        </div >

    );
}


// export default withAuthenticator(AVRT)
export default AVRT;