import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import Task from "../components/task"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
// import { Button, t } from '@material-ui/core';
import { Grid, Card, CardHeader, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PatientHeader from "../components/PatientHeader"
import CircularProgress from '@mui/material/CircularProgress';
import PatientDetails from "../components/PatientDetails"
import PatientDocs from "../components/PatientDocs"
import PatientAppts from "../components/PatientAppts"
// import { Grid } from '@material-ui/core';
import awsGlobal from "../aws-global"
// Amplify.configure(awsExports);
// import awsGlobal from "../../../aws-global";
Amplify.configure(
    awsExports +
    {
        cookieStorage: {
            domain: "dev.avrt.taplow.dev",
            secure: false,
            path: "/",
            expires: 365,
        },
    }
);
const PatientPortal = (props) => {
    // for Amplify to work manually https://docs.amplify.aws/ui/auth/authenticator/q/framework/react/#basic-usage
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();


    const [idToken, setIdToken] = useState('')

    const [tasks, setTasks] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [tasksCount, setTasksCount] = useState(0)
    const [cognito, setCognito] = useState({})
    const [region, setRegion] = useState('')
    const [awsUrl, setAwsUrl] = useState('')

    useEffect(() => {
        if (authState !== AuthState.SignedIn || awsUrl === '') {
            return
        }
        var fetch_url = awsUrl + `/patient_task`;
        axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + idToken, 'Content-Type': 'application/json' } }).then(function (response) {
            setTasks(response.data)
            setLoaded(true)
            console.log('TASKS1', response.data)
        }
        )
    }, [awsUrl, idToken])

    useEffect(() => {
        if (authState !== AuthState.SignedIn) {
            return
        }
        if (region) {
            setAwsUrl(awsGlobal.aws_json_patient[awsGlobal.env][region].api_gw_url)
        }
    }, [region, idToken])

    useEffect(() => {
        if (authState !== AuthState.SignedIn) {
            return
        }
        let tc = 0
        tasks.map(({ id, status, type, uuid, header, subheader, description, progress, button, button_link, current_progress, total_actions }) => {
            // console.log('INCOMPLETE', current_progress, total_actions)
            if (status !== 'done') {
                tc += 1
            }
        })
        setTasksCount(tc)
    }, [tasks])

    useEffect(() => {
        if (authState === undefined) {
            Auth.currentAuthenticatedUser().then(authData => {
                setAuthState(AuthState.SignedIn);
                setUser(authData);
            });
        }

        onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            Auth.currentSession().then((res) => {
                let accessToken = res.getAccessToken();
                let jwt = accessToken.getJwtToken();
                setIdToken(res.getIdToken().jwtToken)
                setRegion(res.getIdToken().payload['custom:region_code'])
                setCognito(res.getIdToken().payload)
            });
        });




    }, [authState]);
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    return authState === AuthState.SignedIn && user ? (
        <div>
            <PatientHeader />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <h2>My details</h2>
                    <PatientDetails auth={idToken} region={region} awsUrl={awsUrl} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>

                    <h2>My tasks</h2>
                    {loaded ?
                        <>
                            {(tasksCount === 0) ?
                                <>
                                    All tasks completed
                                </>
                                :
                                <>
                                    {
                                        tasks.map(({ id, status, type, uuid, header, subheader, description, progress, button, button_link, current_progress, total_actions, pass_uuid, dialog, selected_opt }) => {

                                            if (status !== 'done') {
                                                return (
                                                    <Task id={id} status={status} type={type} uuid={uuid} header={header} subheader={subheader} description={description} progress={progress} button={button} button_link={button_link} current_progress={current_progress} total_actions={total_actions} completed={false} awsUrl={awsUrl} pass_uuid={pass_uuid} dialog={dialog} auth={idToken} selected_opt={selected_opt} />
                                                )
                                            }



                                        }
                                        )
                                    } </>} </> : <>< CircularProgress /></>}
                    <h2>Completed tasks</h2>
                    {loaded ?
                        <>
                            {
                                tasks.map(({ id, status, type, uuid, header, subheader, description, progress, button, button_link, current_progress, total_actions, pass_uuid, dialog, selected_opt }) => {
                                    if (status === 'done') {
                                        return (
                                            <Task id={id} status={status} type={type} uuid={uuid} header={header} subheader={subheader} description={description} progress={progress} button={button} button_link={button_link} current_progress={current_progress} total_actions={total_actions} completed={true} awsUrl={awsUrl} pass_uuid={pass_uuid} dialog={dialog} auth={idToken} selected_opt={selected_opt} />
                                        )
                                    }


                                }
                                )
                            } </> : <></>}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2>My appointments</h2>
                    <PatientAppts auth={idToken} awsUrl={awsUrl} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>

                    <PatientDocs auth={idToken} awsUrl={awsUrl} />
                </Grid>
            </Grid>



        </div>

    ) : (
        <>
            < AmplifyAuthenticator >
                <AmplifySignIn headerText="AVRT Patient Portal sign-in" slot="sign-in" hideSignUp></AmplifySignIn>
            </AmplifyAuthenticator >
        </>
    );
}
export default PatientPortal
