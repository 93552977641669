
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "./survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import awsvars from "../aws.json"
import axios from "axios";
import fileDownload from 'js-file-download'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
Amplify.configure(awsExports);

const PatientDocs = (props) => {
    const [idToken, setIdToken] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [docs, setDocs] = useState([])
    const [url, setUrl] = useState('')
    async function fetch() {

        if (props.awsUrl === '') {
            return
        }
        if (props.auth) {
            const drchrono = await axios.get(props.awsUrl + '/patient_docs', { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
            setDocs(drchrono.data)
            if (drchrono.data.data !== []) {
                setLoaded(true)
            }
        }

    }

    async function fetch_doc_link(id) {
        if (props.awsUrl === '') {
            return
        }
        const drchrono = await axios.get(props.awsUrl + '/patient_doc_link?doc_id=' + id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
        return drchrono.data.document
    }

    async function download_doc_link(id) {
        if (props.awsUrl === '') {
            return
        }
        axios.get(props.awsUrl + '/patient_doc_link?doc_id=' + id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } })
            .then((res) => {
                axios.get(res.data.document, {
                    responseType: 'blob',
                })
                    .then((res2) => {
                        fileDownload(res2.data, res.data.description)
                    })

            })
    }

    useEffect(() => {
        fetch()

    }, [props.auth, props.awsUrl]);

    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return (
        <div>
            {loaded ?
                <>
                    <h2>My documents</h2>
                    {
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {docs.map(({ date, description, id }) => {
                                    var url = props.awsUrl + '/patient_doc?doc_id=' + id
                                    return (
                                        <TableRow>
                                            <TableCell>{date}</TableCell>
                                            <TableCell>{description}</TableCell>
                                            <TableCell><Button onClick={async () => { var urlc = await fetch_doc_link(id); window.open(urlc) }}>View</Button><Button onClick={async () => { var urlc = await download_doc_link(id); }}>Download</Button></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    } </> : <></>
            }
        </div >
    );
}
export default withAuthenticator(PatientDocs)
