
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "./survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import awsvars from "../aws.json"
import axios from "axios";
import fileDownload from 'js-file-download'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import awsGlobal from "../aws-global"
Amplify.configure(awsExports);

const PatientAppts = (props) => {
    const [idToken, setIdToken] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [appts, setAppts] = useState([])
    const [url, setUrl] = useState('')

    async function get_aws_url() {

    }

    useEffect(() => {
        if (props.awsUrl === '') {
            return
        }
        async function fetch() {
            if (props.auth) {

                const url = props.awsUrl + '/patient_appts'

                const drchrono = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });

                setAppts(drchrono.data)
                setLoaded(true)
            }

        }

        fetch()

    }, [fetch, props.auth, props.awsUrl]);

    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return (
        <div>
            {loaded ?
                <>
                    {
                        <>
                            {appts.map(({ staff_title, staff_firstname, staff_surname, join_url, duration, start_time_email_formatted, type_name, utc_ts }) => {
                                var date = new Date(utc_ts * 1000).toString()
                                var formatted = start_time_email_formatted.replace('/<b>', ' (').replace('</b>', ')')
                                return (

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >

                                            <Typography><Button variant="contained" onClick={async () => { window.open(join_url) }}>Join Meeting</Button>&nbsp;{type_name} - {staff_title} {staff_firstname} {staff_surname} - {date} </Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Full details about your meeting.<br />
                                                {formatted}

                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </>
                    } </> : <>< CircularProgress /></>
            }
        </div >
    );
}
export default withAuthenticator(PatientAppts)
