import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "./index.css";
import SurveyContainer from "./components/survey"

import AVRT from "./pages/AVRT";

import PatientPortal from "./pages/PatientPortal";
import PatientSurvey from "./pages/PatientSurvey";
import Container from '@mui/material/Container';
function App() {



  function sendDataToServer() {

  }

  return (
    <Router>

      <div>
        <Container maxWidth="lg">
          <Switch>
            <Route exact path='/' component={PatientPortal} />
     
            <Route path='/patient' component={PatientPortal} />
            <Route path='/patient_survey' component={PatientSurvey} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
}

export default App;
